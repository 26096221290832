import styled from 'styled-components'

export const Section = styled.section`

img {
  width: 100%;
}

.description {
  min-height: 60px;
}

`
