import React, { useState } from 'react'
import data from '../../../static/Investments/investmentsTab.json'
import { InvestmentsCard } from '../InvestmentsCard'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import './investmentsTab.scss'

type InvestmentsTabDataChildrenProps = {
  title: string;
  description: string;
  benefitOne?: string;
  benefitTwo?: string;
  minimumInvestment?: string;
  link: string;
  id: string;
  list?: {
    icon: string[];
  };
}

type InvestmentsTabDataProps = {
  buttonTab: string;
  children: InvestmentsTabDataChildrenProps[];
}

type DataLayerProps = {
  section: string;
  elementAction: 'click button';
  section_name: string;
}

function InvestmentsTab ({ section, elementAction, section_name }: DataLayerProps) {
  const [ currentItem, setCurrentItem ] = useState(0)
  const slug = typeof window !== 'undefined' ? window.location.pathname : ''
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <>
      <div className='navInvestmentsTab shadow py-4 px-md-3 mt-lg-5 rounded-3'>
        <div className='d-flex pb-4'>
          {data.map((item: InvestmentsTabDataProps, index: number) => (
            <button
              key={item.buttonTab}
              className={`btnInvestments col mw-100 rounded-3 fs-14 mx-2 ${currentItem === index ? 'btn-orange' : 'border'}`}
              onClick={() => setCurrentItem(index)}
            >{item.buttonTab}
            </button>
          ))}
        </div>
        {data.map((item: InvestmentsTabDataProps, index: number) => {
          return (
            <div key={item.buttonTab} className={`px-2 ${currentItem === index ? 'd-block' : 'd-none'}`}>
              <div className='row'>
                {item.children.map((item: InvestmentsTabDataChildrenProps) => {
                  return (
                    <InvestmentsCard
                      onClick={() => {
                        sendDatalayerEvent({
                          section: section,
                          element_action: elementAction,
                          element_name: item.title,
                          section_name: section_name,
                          redirect_url: `${window.location.origin}${item.link}`,
                        })
                      }}
                      key={item.title}
                      link={item.link}
                      title={item.title}
                      description={item.description}
                      benefitOne={item.benefitOne}
                      benefitTwo={item.benefitTwo}
                      list={item.list}
                      id={item.id}
                      slug={slug}
                    />
                  )
                })
                }
              </div>
            </div>
          )
        },
        )
        }
      </div>
    </>
  )
}

export default (InvestmentsTab)
