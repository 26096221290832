import React from 'react'
import Img from 'gatsby-image'

import Layout from 'src/layouts/BaseLayout'
import InvestmentsTab from 'src/components/InvestmentsTab'
import FAQ from 'src/components/Faq'
import BlogArticles from 'src/components/BlogArticles'
import Icon from 'src/components/UI/MarkdownIcon'
import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Home from '@interco/icons/build-v4/orangeds/MD/home'
import { Link } from 'gatsby'
import headerJson from './assets/data/hero.json'

import pageContext from './pageContext.json'
import usePageQuery from './pageQuery'
import { Section, FAQSection } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'

const { qrCodeContaKidsAberturaDeContasInvestimentos, qrCodeAberturaDeContaRendaFixaLca } = qrcodeInvestimentsData

function RendaFixaLCA () {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isModalOpen, setIsModalOpen ] = React.useState(false)
  const domReady = useDomReady()

  const cardsModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        urlContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.deeplink}
        qrCodeContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.qrcode}
        closeModal={() => setIsModalOpen(false)}
        qrBaixeApp={qrCodeAberturaDeContaRendaFixaLca.qrcode}
        asFilledLink={qrCodeAberturaDeContaRendaFixaLca.deeplink}
      />
    </Modal>
  )

  const dataLayer: IDataLayerParams = {
    section: 'dobra_06',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

  return (
    <Layout pageContext={pageContext}>
      {cardsModal}
      <Section className=''>
        <div className='container pt-4'>
          <div className='row align-items-md-center'>
            <div className='col-12 col-md-6 col-lg-5 col-xl-6 offset-lg-1 offset-xl-0 order-md-last'>
              <Img fluid={data.imgHero.fluid} alt='Tela do Super App da Inter Invest mostrando como investir em LCA.' />
            </div>
            <div className='col-12 col-md-6 col-lg-6 col-xl-6 pr-md-0'>
              <div className='bread'>
                <Link
                  to='/'
                  className='d-md-inline'
                ><Home height={24} width={24} color='#6A6E81' />
                </Link>
                <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
                <Link
                  to='/pra-voce/investimentos/'
                  className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
                >
                  {headerJson.breadcrumb[0]}
                </Link>
                <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
                <Link
                  to='/pra-voce/investimentos/renda-fixa/'
                  className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
                >
                  {headerJson.breadcrumb[1]}
                </Link>
                <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
                <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{headerJson.breadcrumb[2]}</p>
              </div>
              <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 mb-3 text-grayscale--500 mt-3 mt-lg-0 fw-600'>
                LCA é um investimento <span className='d-lg-block'>seguro e com ganhos</span> acima da poupança
              </h1>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500'>
                Investir em LCA - Letra de Crédito do Agronegócio <span className='d-lg-block'>é uma excelente opção para aplicar seu dinheiro em</span><span className='d-lg-block'>um ativo isento de IR e com rentabilidade maior que a </span>Poupança.
              </p>
              <div className='d-block d-md-none mt-2 mt-4 mb-5'>
                <a href='bancointer://investimentos/investir/rendafixa/18121' target='_blank' rel='noreferrer' className='btn btn--lg btn-orange--extra fs-14 fw-700 rounded-3 text-none ml-lg-0 mw-100'>Começar agora!</a>
              </div>
              <div>
                <button
                  className='btn btn--orange btn--lg rounded-3 mw-100'
                  onClick={() => {
                    setIsModalOpen(true)
                  }}
                >
                  Conheça
                </button>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <section id='outros-investimentos' className='py-5 pb-md-5'>
        <div className='container pb-lg-3'>
          <h2 className='fs-28 fs-md-42 text-md-center mt-2 mb-4 text-grayscale--500'>Invista com segurança e rentabilidade</h2>
          <div className='d-flex justify-content-center'>
            <div className='col-12 col-md-8 col-lg-5 px-0'>
              <p className='fs-16 text-md-center'>Confira as caracteristicas do LCA, invista e veja seu dinheiro render.</p>
            </div>
          </div>
          <div className='row box-features'>
            <div className='col-12 col-md col-lg mt-2'>
              <div className='col-12 border rounded-3 d-flex d-md-block'>
                <div className='col-2 col-md-12 pt-3 pt-md-4 pt-lg-3 text-md-center px-0'>
                  <Icon width='40' height='40' icon='pagamentos/ir' directory='v2' />
                </div>
                <div className='col-10 col-md-12'>
                  <p className='fs-14 lh-20 mt-3 text-md-center'>Imposto de Renda<br />
                    <span className='fs-17 fw-400'>Isento </span>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-12 col-md col-lg mt-2'>
              <div className='col-12 border rounded-3 d-flex d-md-block'>
                <div className='col-2 col-md-12 pt-3 pt-md-4 pt-lg-3 text-md-center px-0'>
                  <Icon width='40' height='40' icon='products/investimentos/fundos-2' directory='v2' />
                </div>
                <div className='col-10 col-md-12'>
                  <p className='fs-14 lh-20 mt-3 text-md-center'>Investimento Inicial<br />
                    <span className='fs-17 fw-400'>R$ 100,00</span>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-12 col-md col-lg mt-2'>
              <div className='col-12 border rounded-3 d-flex d-md-block'>
                <div className='col-2 col-md-12 pt-3 pt-md-4 pt-lg-3 text-md-center px-0'>
                  <Icon width='40' height='40' icon='pagamentos/fast-money' directory='v2' />
                </div>
                <div className='col-10 col-md-12'>
                  <p className='fs-14 lh-20 mt-3 text-md-center'>Resgate<br />
                    <span className='fs-17 fw-400'>No vencimento</span>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-12 col-md col-lg mt-2'>
              <div className='col-12 border rounded-3 d-flex d-md-block'>
                <div className='col-2 col-md-12 pt-3 pt-md-4 pt-lg-3 text-md-center px-0'>
                  <Icon width='40' height='40' icon='action/calendar' directory='v2' />
                </div>
                <div className='col-10 col-md-12'>
                  <p className='fs-14 lh-20 mt-3 text-md-center'>Prazo mínimo<br />
                    <span className='fs-17 fw-400'>90 dias</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='outros-investimentos'>
        <div className='container pt-5 py-md-5'>
          <h2 className='fs-28 fs-md-42 text-md-center mb-4 mt-2'>Veja outros tipos de investimentos</h2>
          <InvestmentsTab
            section='dobra_3'
            elementAction='click button'
            section_name='Veja outros tipos de investimentos'
          />
        </div>
      </section>

      <section id='novidades-investimentos' className='py-md-4'>
        <div className='container'>
          <div className='col-12 mb-4'>
            <h2 className='fs-28 mb-0 fs-lg-42 text-left text-lg-center mb-4'>
              Os melhores conteúdos sobre investimentos
            </h2>
            <p className='text-left mt-1 text-lg-center mb-0'>
              Esteja atualizado com as novidades do blog e invista com segurança aumentando os seus resultados.
            </p>
          </div>
          <BlogArticles
            type='investments'
            section='dobra_4'
            elementAction='click button'
            elementName='Saiba mais'
            sectionName='Confira algumas dicas para investir melhor'
          />
        </div>
      </section>

      <FAQSection id='perguntas-frequentes' className='bg-gray py-5 pb-4 pb-md-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb-3'>
              <h2 className='fs-28 fs-md-42 mb-4 text-md-center'>Perguntas frequentes</h2>
              <p className='fs-16 text-md-center'>
                <span className='d-lg-block'>Ainda tem dúvidas? Veja se podemos te ajudar respondendo algumas</span> perguntas ou entre em contato com a gente.
              </p>
            </div>
            <FAQ className='summary-content px-0' answerData={pageContext.structuredData.faq} searchBg='#fff' />
          </div>
        </div>
      </FAQSection>
      <DisclaimerInterInvest
        sendDatalayerEvent={sendDatalayerEvent}
        dataLayer={dataLayer}
        section='dobra_06'
      />
    </Layout>
  )
}

export default RendaFixaLCA
